import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class SubsidiaryService {

  constructor(private http: HttpClient) {
  }

  getSubsidiaries(): Observable<any> {
    return this.http.get('/api/subsidiaries');
  }

  createSubsidiary(subsidiary: {name: string}): Observable<any> {
    return this.http.post('/api/subsidiaries', subsidiary);
  }

}
